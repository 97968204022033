// Copyright 2021 - 2024 Universität Tübingen, DKFZ and EMBL
// for the German Human Genome-Phenome Archive (GHGA)
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

$primary: #00393f;
$secondary: #e84614;
$success: #6bb565;
$info: #2f80ed;
$light: #e0e0e0;
$warning: #e2b93b;
$error: #eb5757;

$carousel-control-width: 3%;

// default z-index for modal backdrops in bootstrap
$zIndex: 1040;
@for $i from 1 through 6 {
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndex;
  }
  $zIndex: $zIndex + 1;
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndex;
  }
  $zIndex: $zIndex + 1;
}

div.version-ribbon {
  background-color: #b11;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  height: 30px;
  width: 260px;
  left: -50px;
  top: 65px;
  transform: rotate(-45deg);
  box-shadow: 0 0 10px #888;
  z-index: 999;

  span {
    display: inline-block;
    color: #fee;
    font-weight: bold;
    width: 200px;
    margin: 1px 30px;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    text-shadow: 0 0 5px #444;
    text-size-adjust: none;
    cursor: pointer;
  }
}
